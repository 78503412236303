import React from 'react';
import styles from './Userprofile.module.css';
import { IUserList } from '../InvitePeople/InvitePeople';
import defaulatImage from '../../images/user.png';

interface IUserProfile {
  item: IUserList;
}

const colorObj: { [key: string]: string } = {
  Blue: 'blueShield',
  White: 'whiteShield',
  Red: 'redShield',
  Yellow: 'yellowShield',
  Green: 'greenShield',
};

const Userprofile: React.FC<IUserProfile> = ({ item }) => {
  return (
    <div
      // whiteShield || redShield || yellowShield || greenShield || blueShield
      className={`${styles.mainProfileBox} ${styles[colorObj[item.badge]]} ${
        styles.offline
      } ${styles.tenMember}`}
    >
      <img
        className={`${styles.userProfile}`}
        src={item.profile_url !== null ? item.profile_url : defaulatImage}
      />

      {item.is_tenk && (
        <div className={`${styles.sheild}`}>
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.91 10.12C18.91 15.01 15.36 19.59 10.51 20.93C10.18 21.02 9.81996 21.02 9.48996 20.93C4.63996 19.59 1.08997 15.01 1.08997 10.12V5.72997C1.08997 4.90997 1.70998 3.97998 2.47998 3.66998L8.04996 1.39001C9.29996 0.880007 10.71 0.880007 11.96 1.39001L17.53 3.66998C18.29 3.97998 18.92 4.90997 18.92 5.72997L18.91 10.12Z"
              fill="#D9D9D9"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>10k</span>
        </div>
      )}

      <img
        className={`${styles.profileBgDots}`}
        src="https://staging.dmdb.com/assets/ProfileBackground-ff5e92df04eb472cf36380a296b3d791d0467a9467b2e5fdabed3602d602cd02.png"
      />
    </div>
  );
};

export default Userprofile;
