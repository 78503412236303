import dmdbApi from '../services/dmdbApiService';

export const getUserDetails = () => {
  return dmdbApi.get<UserDetailResponse>(
    '/api/v1/watch_party/users/user_details'
  );
};

export const isUserFanOrFriend = (userId: string) => {
  return dmdbApi.post(`/api/v1/watch_party/users/user_friend_fan`, {
    user: userId,
  });
};

export const kickUser = (roomId: string, userId: string) => {
  const room = roomId.replaceAll('/', '');
  return dmdbApi.post(`/api/v1/watch_party/watch_parties/${room}/kick_out`, {
    user: userId,
  });
};

export const checkRoomAccess = (roomId: string, userId: string) => {
  const room = roomId.replaceAll('/', '');
  return dmdbApi.post<{
    access: boolean;
    message: string;
  }>(`/api/v1/watch_party/watch_parties/${room}/check_access`, {
    user: userId,
  });
};

export const startParty = (roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties/${roomId.replaceAll('/', '')}/started`
  );
};

export const endParty = (roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties/${roomId.replaceAll('/', '')}/ended`
  );
};

export const changeHost = (user: string, roomId: string) => {
  return dmdbApi.post(
    `/api/v1/watch_party/watch_parties${roomId}/assign_co_host`,
    { user }
  );
};

export const getUsersList = (
  page: number,
  search: string,
  user_type: 'all' | 'fans' | 'friends'
) => {
  return dmdbApi.post(`api/v1/watch_party/users/user_list`, {
    page,
    search,
    user_type: user_type !== 'all' ? user_type : undefined,
  });
};

export const sendInvite = (
  roomId: string,
  targetType: string[],
  users: string[],
  externalType: string[],
  extraEmails: string,
  extraPhones: string
) => {
  const room = roomId.replaceAll('/', '');
  return dmdbApi.put(`/api/v1/watch_party/watch_parties/${room}`, {
    watch_party: {
      target_type: targetType,
      user_ids: users.length ? users : undefined,
      externals: externalType.length ? externalType : undefined,
      extra_emails: extraEmails.length ? extraEmails : undefined,
      extra_phones: extraPhones.length ? extraPhones : undefined,
    },
  });
};
