import React, { SetStateAction } from 'react';

export interface IExternalUsers {
  isEnabled: { email: boolean; text: boolean };
  emails: string;
  setEmails: React.Dispatch<SetStateAction<string>>;
  setIsEnabled: React.Dispatch<
    SetStateAction<{ email: boolean; text: boolean }>
  >;
  texts: string;
  setTexts: React.Dispatch<SetStateAction<string>>;
  errors: { emailError: string; phoneError: string };
}
const ExternalUser: React.FC<IExternalUsers> = ({
  isEnabled,
  emails,
  setEmails,
  setIsEnabled,
  texts,
  setTexts,
  errors,
}) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
        <label
          className="custom-container"
          style={{ width: 'auto', paddingLeft: 25 }}
        >
          <span className="text-gradient-orange" style={{ fontSize: 16 }}>
            External Email
          </span>
          <input
            type="checkbox"
            name="checboxname"
            id="email check"
            checked={isEnabled.email}
            onChange={(e) => {
              if (e.target.checked) {
                setEmails('');
              }
              setIsEnabled({ ...isEnabled, email: e.target.checked });
            }}
          />
          <span className="checkmark"></span>
        </label>
        <label
          className="custom-container"
          style={{ width: 'auto', paddingLeft: 25 }}
        >
          <span className="text-gradient-orange" style={{ fontSize: 16 }}>
            Text
          </span>
          <input
            type="checkbox"
            name="checboxname"
            id="text check"
            checked={isEnabled.text}
            onChange={(e) => {
              if (e.target.checked) {
                setTexts('');
              }
              setIsEnabled({ ...isEnabled, text: e.target.checked });
            }}
          />
          <span className="checkmark"></span>
        </label>
      </div>
      <div style={{ margin: '20px 0' }}>
        {isEnabled.email && (
          <>
            <input
              className="input-border"
              disabled={!isEnabled.email}
              placeholder="Enter email"
              value={emails}
              onChange={(e) => {
                setEmails(e.target.value);
              }}
            />
            <p style={{ color: '#AE8A27', fontSize: 12, fontWeight: 100 }}>
              Example: abc@xyz.com, abc1@xyz.com
            </p>
            {errors.emailError.length ? (
              <p style={{ color: 'red' }}>{errors.emailError}</p>
            ) : null}
          </>
        )}
        {isEnabled.text && (
          <>
            <input
              className="input-border"
              disabled={!isEnabled.text}
              placeholder="Enter phone number"
              value={texts}
              onChange={(e) => {
                setTexts(e.target.value);
              }}
            />
            <p style={{ color: '#AE8A27', fontSize: 12, fontWeight: 100 }}>
              Example: +1 9988888787, +1 9988888787
            </p>
            {errors.phoneError.length ? (
              <p style={{ color: 'red' }}>{errors.phoneError}</p>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ExternalUser;
