import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import {
  Icon,
  Divider,
  Radio,
  CheckboxProps,
  Message,
  Input,
  Button,
  Label,
  Popup,
  SemanticICONS,
  DropdownProps,
} from 'semantic-ui-react';
// import { SignInButton } from '../TopBar/TopBar';
import { getCurrentSettings, updateSettings } from './LocalSettings';
import axios from 'axios';
import { serverPath } from '../../utils';
import { PermanentRoomModal } from '../Modal/PermanentRoomModal';
import firebase from 'firebase/compat/app';
import { Socket } from 'socket.io-client';
import { HexColorPicker } from 'react-colorful';
import { getJwtToken } from '../../utils/auth';
import { endParty, sendInvite } from '../../api/user';
import HostLeaveDialog from '../HostLeaveDialog/HostLeaveDialog';

const defaultRoomTitleColor = '#FFFFFF';
const roomTitleMaxCharLength = 50;
const roomDescriptionMaxCharLength = 120;

interface SettingsTabProps {
  hide: boolean;
  user: firebase.User | User;
  roomLock: string;
  setRoomLock: (lock: boolean) => Promise<void>;
  socket: Socket;
  isSubscriber: boolean;
  roomId: string;
  owner: string | undefined;
  setOwner: (owner: string) => void;
  vanity: string | undefined;
  setVanity: (vanity: string) => void;
  roomLink: string;
  password: string | undefined;
  setPassword: (password: string) => void;
  isChatDisabled: boolean;
  setIsChatDisabled: (disabled: boolean) => void;
  clearChat: () => void;
  roomTitle: string | undefined;
  setRoomTitle: (title: string) => void;
  roomDescription: string | undefined;
  setRoomDescription: (desc: string) => void;
  roomTitleColor: string | undefined;
  setRoomTitleColor: (color: string) => void;
  mediaPath: string | undefined;
  setMediaPath: (path: string) => void;
  participants: User[];
  setMedia: (e: any, data: DropdownProps) => void;
  settings: RoomSettings;
  getRoomState: () => void;
  nameMap: StringDict;
  pictureMap: StringDict;
  coHosts: string[];
}

export const SettingsTab = ({
  hide,
  user,
  roomLock,
  setRoomLock,
  socket,
  isSubscriber,
  owner,
  vanity,
  setVanity,
  roomLink,
  password,
  setPassword,
  isChatDisabled,
  setIsChatDisabled,
  clearChat,
  roomTitle,
  roomDescription,
  roomTitleColor,
  mediaPath,
  setMediaPath,
  participants,
  setMedia,
  settings,
  roomId,
  getRoomState,
  nameMap,
  pictureMap,
  setOwner,
  coHosts,
}: SettingsTabProps) => {
  console.log(coHosts, 'ccc');
  const [showAdminLeaveModal, setShowAdminLeaveModal] =
    useState<boolean>(false);
  const isFirstTimeLoading = useRef(true);
  const [updateTS, setUpdateTS] = useState(0);
  const [permModalOpen, setPermModalOpen] = useState(false);
  const [validVanity, setValidVanity] = useState(true);
  const [validVanityLoading, setValidVanityLoading] = useState(false);
  const [adminSettingsChanged, setAdminSettingsChanged] = useState(false);
  const [roomTitleInput, setRoomTitleInput] = useState<string | undefined>(
    undefined
  );
  const [peopleAllowed, setPeopleAllowed] = useReducer(
    (prev: RoomSettings, next: RoomSettings) => {
      if (next.isFriendAllowed || next.isFanAllowed) {
        return { ...prev, ...next, isEveryoneAllowed: false };
      }

      if (next.isEveryoneAllowed) {
        return {
          ...prev,
          ...next,
          isFanAllowed: false,
          isFriendAllowed: false,
        };
      }
      return { ...prev, ...next };
    },
    {
      isFanAllowed: false,
      isFriendAllowed: false,
      isEveryoneAllowed: true,
      ...settings,
    }
  );
  const [isUpdatingRoom, setIsUpdatingRoom] = useState(false);

  const [roomDescriptionInput, setRoomDescriptionInput] = useState<
    string | undefined
  >(undefined);
  const [roomTitleColorInput, setRoomTitleColorInput] = useState<
    string | undefined
  >('');

  useEffect(() => {
    if (settings) {
      setPeopleAllowed(settings);
    }
  }, [settings]);
  useEffect(() => {
    if (isFirstTimeLoading.current) {
      isFirstTimeLoading.current = false;
    }
  }, []);

  const setRoomState = useCallback(
    async (data: any) => {
      const token = getJwtToken();

      socket.emit('CMD:setRoomState', {
        uid: user?.uid,
        token,
        ...data,
      });
    },
    [socket, user]
  );
  const setRoomOwner = useCallback(
    async (data: any) => {
      const token = getJwtToken();

      await socket.emit('CMD:setRoomOwner', {
        uid: user?.uid,
        token,
        ...data,
      });
    },
    [socket, user]
  );
  const checkValidVanity = useCallback(
    async (input: string) => {
      if (!input) {
        setValidVanity(true);
        return;
      }
      setValidVanity(false);
      setValidVanityLoading(true);
      const response = await axios.get(serverPath + '/resolveRoom/' + input);
      const data = response.data;
      setValidVanityLoading(false);
      if (
        data &&
        data.vanity &&
        data.vanity !== roomLink.split('/').slice(-1)[0]
      ) {
        // Already exists and doesn't match current room
        setValidVanity(false);
      } else {
        setValidVanity(true);
      }
    },
    [setValidVanity, roomLink]
  );
  const disableLocking =
    !Boolean(user) || Boolean(roomLock && roomLock !== user?.uid);
  const disableOwning = !Boolean(user) || Boolean(owner && owner !== user?.uid);

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const updateRoom = async () => {
    setIsUpdatingRoom(true);
    const token = getJwtToken();

    try {
      await axios.put(
        `${serverPath}/updateRoom?uid=${user?.uid}&token=${token}&roomId=${roomId}`,
        { settings: peopleAllowed }
      );
      getRoomState();
    } catch (error) {
      console.log('Error while updating room');
      console.log(error);
    } finally {
      setIsUpdatingRoom(false);
    }
  };

  useEffect(() => {
    if (!isFirstTimeLoading.current) {
      updateRoom();
    }
  }, [peopleAllowed]);

  const getParticipants = () => {
    if (participants.length === 1 && participants[0]?.uid === owner) {
      console.log('participants------', participants);
      return <p>The host is present</p>;
    }
    return (
      <p>
        {participants.length} {participants.length === 1 ? 'person' : 'people'}{' '}
        joined
      </p>
    );
  };

  const redirectToWatchPartyDomain = () => {
    if (process.env.REACT_APP_DMDB_DOMAIN) {
      window.location.href = process.env.REACT_APP_DMDB_DOMAIN;
    } else {
      window.location.href = 'http://www.dmdb.com';
    }
  };

  const isOwner = owner === user?.uid;

  return (
    <div
      style={{
        display: hide ? 'none' : 'block',
        color: 'white',
        overflow: 'scroll',
        padding: '8px',
      }}
    >
      <HostLeaveDialog
        participants={participants}
        socket={socket}
        open={showAdminLeaveModal}
        setOpen={setShowAdminLeaveModal}
        roomId={roomId}
        nameMap={nameMap}
        pictureMap={pictureMap}
        setMedia={setMedia}
        redirectToWatchPartyDomain={redirectToWatchPartyDomain}
      />
      {permModalOpen && (
        <PermanentRoomModal
          closeModal={() => setPermModalOpen(false)}
        ></PermanentRoomModal>
      )}
      <div className="newsectionHeader">People Allowed</div>
      <div className="mb-28">
        <NewSettingRow
          toggle
          icon={''}
          name={`Friends`}
          description=""
          checked={Boolean(peopleAllowed.isFriendAllowed)}
          disabled={isUpdatingRoom || owner !== user?.uid}
          // disabled={disableLocking && disableOwning}
          onChange={(_e, data) => {
            setPeopleAllowed({ isFriendAllowed: Boolean(data.checked) });
            sendInvite(roomId, data.checked ? ['Friends'] : [], [], [], '', '');
          }}
        />
      </div>
      <div className="mb-28">
        <NewSettingRow
          toggle
          icon={''}
          name={`Fans`}
          description=""
          checked={Boolean(peopleAllowed.isFanAllowed)}
          disabled={isUpdatingRoom || owner !== user?.uid}
          // disabled={disableLocking && disableOwning}
          onChange={(_e, data) => {
            setPeopleAllowed({ isFanAllowed: Boolean(data.checked) });
            sendInvite(roomId, data.checked ? ['Fans'] : [], [], [], '', '');
          }}
        />
      </div>
      <div className="mb-34">
        <NewSettingRow
          toggle
          icon={''}
          name={`Anyone`}
          description=""
          checked={Boolean(peopleAllowed.isEveryoneAllowed)}
          disabled={isUpdatingRoom || owner !== user?.uid}
          // disabled={disableLocking && disableOwning}
          onChange={(_e, data) => {
            setPeopleAllowed({ isEveryoneAllowed: Boolean(data.checked) });
            sendInvite(
              roomId,
              data.checked ? ['Block Party'] : [],
              [],
              [],
              '',
              ''
            );
          }}
        />
      </div>
      <div className="partylink">Party Link</div>
      <div className="description m-20">
        <p className="mb-26">
          Share the link with others and then everyone can watch together.
          They’ll need to join from web browser on their computer.
        </p>
        <span>{window.location.href}</span>
      </div>
      <div
        style={{ display: 'flex', gap: 15 }}
        className="copysharebutton m-65"
      >
        <Popup
          on="click"
          basic
          trigger={
            <Button
              className="copybutton"
              onClick={() => handleCopyInviteLink()}
            >
              <Icon name="copy" className="mr-14" />
              Copy Link
            </Button>
          }
          closeOnEscape
          closeOnDocumentClick
          closeOnPortalMouseLeave
          closeOnTriggerClick
          position="top center"
          content="Copied"
        />

        <Button className="sharebutton">
          <Icon name="share alternate" className="mr-14" />
          Share Link
        </Button>
      </div>
      <div className="partylink">Watch Party details</div>
      <div className="description m-20">{getParticipants()}</div>
      <div className="copysharebutton">
        <Button
          className="sharebutton w-100"
          onClick={async () => {
            if (isOwner) {
              setShowAdminLeaveModal(true);
            } else {
              socket.emit('CMD:leaveVideo');
              redirectToWatchPartyDomain();
            }
          }}
        >
          {/* <Icon name="share alternate" className='mr-14' /> */}
          {isOwner || coHosts.includes(user?.uid ?? '') ? 'End' : 'Leave'} Watch
          Party
        </Button>
      </div>
      {/* {!user && (
        <Message color="yellow" size="tiny">
          You need to be signed in to change these settings.
        </Message>
      )} */}
      {/* <SettingRow
        toggle
        icon={roomLock ? 'lock' : 'lock open'}
        name={`Lock Room`}
        description="Only the person who locked the room can control the video."
        checked={Boolean(roomLock)}
        disabled={false}
        // disabled={disableLocking && disableOwning}
        onChange={(_e, data) => setRoomLock(Boolean(data.checked))}
      /> */}
      {/* {
        <SettingRow
          toggle
          icon={'clock'}
          name={`Make Room Permanent`}
          description={
            'Prevent this room from expiring. This also unlocks additional room features.'
          }
          helpIcon={
            <Icon
              name="help circle"
              onClick={() => setPermModalOpen(true)}
              style={{ cursor: 'pointer' }}
            ></Icon>
          }
          checked={Boolean(owner)}
          disabled={false}

          // disabled={disableOwning}
          onChange={(_e, data) => setRoomOwner({ undo: !data.checked })}
        />
      }
      {owner && owner === user?.uid && (
        <div className="sectionHeader">Admin Settings</div>
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle={false}
          icon={'key'}
          name={`Set Room Password`}
          description="Users must know this password in order to join the room."
          content={
            <Input
              value={password ?? ''}
              size="mini"
              onChange={(e) => {
                setAdminSettingsChanged(true);
                setPassword(e.target.value);
              }}
              fluid
            />
          }
          disabled={false}
        />
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle={false}
          icon={'folder'}
          name={`Set Room Media Source`}
          description="Set a media source URL with files to replace the default examples. Supports S3 buckets and nginx file servers."
          content={
            <Input
              value={mediaPath ?? ''}
              size="mini"
              onChange={(e) => {
                setAdminSettingsChanged(true);
                setMediaPath(e.target.value);
              }}
              fluid
            />
          }
          disabled={false}
        />
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle
          icon={'i cursor'}
          name={`Disable Chat`}
          description="Prevent users from sending messages in chat."
          checked={Boolean(isChatDisabled)}
          disabled={false}
          onChange={(_e, data) => {
            setAdminSettingsChanged(true);
            setIsChatDisabled(Boolean(data.checked));
          }}
        />
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle={false}
          icon={'trash'}
          name={`Clear Chat`}
          description="Delete all existing chat messages"
          disabled={false}
          content=" "
          rightContent={
            <Button color="red" icon size="mini" onClick={() => clearChat()}>
              <Icon name="trash" />
            </Button>
          }
        />
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle={false}
          icon={'linkify'}
          name={`Set Custom Room URL`}
          description="Set a custom URL for this room. Inappropriate names may be revoked."
          checked={Boolean(roomLock)}
          disabled={!isSubscriber}
          subOnly={true}
          content={
            <React.Fragment>
              <Input
                value={vanity ?? ''}
                disabled={!isSubscriber}
                onChange={(e) => {
                  setAdminSettingsChanged(true);
                  checkValidVanity(e.target.value);
                  setVanity(e.target.value);
                }}
                label={<Label>{`${window.location.origin}/r/`}</Label>}
                loading={validVanityLoading}
                fluid
                size="mini"
                icon
                action={
                  validVanity ? (
                    <Icon name="checkmark" color="green" />
                  ) : (
                    <Icon name="close" color="red" />
                  )
                }
              ></Input>
            </React.Fragment>
          }
        />
      )}
      {owner && owner === user?.uid && (
        <SettingRow
          toggle={false}
          icon={'pencil'}
          name={`Set Room Title, Description & Color`}
          description="Set the room title, description and title color to be displayed in the top bar."
          disabled={!isSubscriber}
          subOnly={true}
          content={
            <React.Fragment>
              <div style={{ display: 'flex', marginBottom: 2 }}>
                <Input
                  style={{ marginRight: 3, flexGrow: 1 }}
                  value={roomTitleInput ?? roomTitle ?? ''}
                  disabled={!isSubscriber}
                  maxLength={roomTitleMaxCharLength}
                  onChange={(e) => {
                    setAdminSettingsChanged(true);
                    setRoomTitleInput(e.target.value);
                  }}
                  placeholder={`Title (max. ${roomTitleMaxCharLength} characters)`}
                  fluid
                  size="mini"
                  icon
                ></Input>
                <Popup
                  content={
                    <React.Fragment>
                      <h5>Edit Title Color</h5>
                      <HexColorPicker
                        color={
                          roomTitleColorInput ||
                          roomTitleColor ||
                          defaultRoomTitleColor
                        }
                        onChange={(e) => {
                          setAdminSettingsChanged(true);
                          setRoomTitleColorInput(e);
                        }}
                      />
                      <div
                        style={{
                          marginTop: 8,
                          paddingLeft: 4,
                          borderLeft: `24px solid ${roomTitleColorInput}`,
                        }}
                      >
                        {roomTitleColorInput?.toUpperCase()}
                      </div>
                    </React.Fragment>
                  }
                  on="click"
                  trigger={
                    <Button
                      icon
                      color="teal"
                      size="tiny"
                      style={{ margin: 0 }}
                      disabled={!isSubscriber}
                    >
                      <Icon name="paint brush" />
                    </Button>
                  }
                />
              </div>
              <Input
                style={{ marginBottom: 2 }}
                value={roomDescriptionInput ?? roomDescription ?? ''}
                disabled={!isSubscriber}
                maxLength={roomDescriptionMaxCharLength}
                onChange={(e) => {
                  setAdminSettingsChanged(true);
                  setRoomDescriptionInput(e.target.value);
                }}
                placeholder={`Description (max. ${roomDescriptionMaxCharLength} characters)`}
                fluid
                size="mini"
                icon
              ></Input>
            </React.Fragment>
          }
        />
      )}
      <div
        style={{
          borderTop: '3px dashed white',
          marginTop: 10,
          marginBottom: 10,
        }}
      />
      {owner && owner === user?.uid && (
        <Button
          primary
          disabled={!validVanity || !adminSettingsChanged}
          labelPosition="left"
          icon
          fluid
          onClick={() => {
            setRoomState({
              vanity: vanity,
              password: password,
              isChatDisabled: isChatDisabled,
              roomTitle: roomTitleInput ?? roomTitle,
              roomDescription: roomDescriptionInput ?? roomDescription,
              roomTitleColor:
                roomTitleColorInput || roomTitleColor || defaultRoomTitleColor,
              mediaPath: mediaPath,
            });
            setAdminSettingsChanged(false);
          }}
        >
          <Icon name="save" />
          Save Admin Settings
        </Button>
      )}
      <div className="sectionHeader">Local Settings</div>
      <SettingRow
        toggle
        updateTS={updateTS}
        icon="bell"
        name="Disable chat notification sound"
        description="Don't play a sound when a chat message is sent while you're on another tab"
        checked={Boolean(getCurrentSettings().disableChatSound)}
        disabled={false}
        onChange={(_e, data) => {
          updateSettings(
            JSON.stringify({
              ...getCurrentSettings(),
              disableChatSound: data.checked,
            })
          );
          setUpdateTS(Number(new Date()));
        }}
      /> */}
    </div>
  );
};

const SettingRow = ({
  icon,
  name,
  description,
  checked,
  disabled,
  onChange,
  content,
  subOnly,
  helpIcon,
  rightContent,
  toggle,
}: {
  icon: string;
  name: string;
  description?: React.ReactNode;
  checked?: boolean;
  disabled: boolean;
  updateTS?: number;
  onChange?: (e: React.FormEvent, data: CheckboxProps) => void;
  content?: React.ReactNode;
  subOnly?: boolean;
  helpIcon?: React.ReactNode;
  rightContent?: React.ReactNode;
  toggle: boolean;
}) => {
  return (
    <React.Fragment>
      <Divider inverted horizontal />
      <div>
        <div style={{ display: 'flex' }}>
          <Icon size="large" name={icon as SemanticICONS} />
          <div>
            {name} {helpIcon}
            {subOnly ? (
              <Label size="mini" color="orange">
                Subscriber only
              </Label>
            ) : null}
          </div>
          {toggle && (
            <Radio
              style={{ marginLeft: 'auto' }}
              toggle
              checked={checked}
              disabled={disabled}
              onChange={onChange}
            />
          )}
          {rightContent && (
            <span style={{ marginLeft: 'auto' }}>{rightContent}</span>
          )}
        </div>
        <div className="smallText" style={{ marginBottom: '8px' }}>
          {description}
        </div>
        {content}
      </div>
    </React.Fragment>
  );
};

const NewSettingRow = ({
  icon,
  name,
  description,
  checked,
  disabled,
  onChange,
  content,
  subOnly,
  helpIcon,
  rightContent,
  toggle,
}: {
  icon: string;
  name: string;
  description?: React.ReactNode;
  checked?: boolean;
  disabled: boolean;
  updateTS?: number;
  onChange?: (e: React.FormEvent, data: CheckboxProps) => void;
  content?: React.ReactNode;
  subOnly?: boolean;
  helpIcon?: React.ReactNode;
  rightContent?: React.ReactNode;
  toggle: boolean;
}) => {
  return (
    <React.Fragment>
      <Divider inverted horizontal />
      <div>
        <div style={{ display: 'flex' }}>
          <div className="toggleText">{name}</div>
          {toggle && (
            <>
              {/* <div className="field">
                <div className="ui toggle checkbox">
                  <input type="checkbox" name="checboxname" id="checboxname" value="any" />
                  <label className="coloring red">any label</label>
                </div>
              </div> */}
              <Radio
                style={{ marginLeft: 'auto' }}
                toggle
                checked={checked}
                disabled={disabled}
                onChange={onChange}
              />
            </>
          )}
        </div>
        {content}
      </div>
    </React.Fragment>
  );
};
