import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Comment,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Modal,
  Popup,
  Segment,
  Tab,
  TabPane,
} from 'semantic-ui-react';
import UsersList from './UsersList';
import ExternalUser from './ExternalUser';
import { getUsersList, sendInvite } from '../../api/user';
import Userprofile from '../Userprofile/Userprofile';

export interface IUserList {
  id: number;
  first_name: string;
  last_name: string;
  number: string;
  email: string;
  login_status: string;
  is_tenk: boolean;
  is_gateway_member: boolean;
  time_zone: string;
  profile_url: string | null;
  badge: string;
}

interface IPagination {
  page: number;
  pages: number;
  next: number;
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^\+\d{1,4}\d{6,15}$/;

const InvitePeople: React.FC<{
  roomId?: string;
  open: boolean;
  setOpen: () => void;
}> = ({ roomId, open, setOpen }) => {
  const [allUsers, setAllUsers] = useState<IUserList[]>([]);
  const [fans, setFans] = useState<IUserList[]>([]);
  const [friends, setFriends] = useState<IUserList[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<IUserList[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [emails, setEmails] = useState<string>('');
  const [texts, setTexts] = useState<string>('');
  const loaderRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [isUserListLoading, setIsUserListLoading] = useState<boolean>(true);
  const [successMessage, setSuccessMessage] = useState<String>('');
  const [openNotification, setOpenNotificatino] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<{ [key: string]: string }>({
    all: '',
    fans: '',
    friends: '',
  });
  const [isEnabled, setIsEnabled] = useState<{ email: boolean; text: boolean }>(
    { email: false, text: false }
  );
  const [errors, setErrors] = useState<{
    emailError: string;
    phoneError: string;
  }>({ emailError: '', phoneError: '' });
  const pageRef = useRef<{
    currentPage: number;
    totalPages: number;
  }>({ currentPage: 0, totalPages: 0 });

  const handleSearchChange = useCallback((tab: string, query: string) => {
    setSearchQuery((prev) => ({
      ...prev,
      [tab]: query,
    }));
  }, []);

  const handleClickOnSelectUser = useCallback(
    (item: IUserList) => {
      if (selectedUsers.length === 50) {
        setError(true);
        return;
      }
      const isExists = selectedUsers.find((i) => i.id === item.id);
      let updatedUser: IUserList[] = [...selectedUsers];
      if (isExists) {
        updatedUser = selectedUsers.filter((i) => i.id !== item.id);
      } else {
        updatedUser.push(item);
      }
      setSelectedUsers(updatedUser);
    },
    [selectedUsers]
  );

  // API call
  const getAllUsers = useCallback(
    async (searchQuery: string, page: number) => {
      try {
        if (selectedTab === 3) return;
        setIsUserListLoading(true);
        const res: {
          data: {
            users: IUserList[];
            pagination: IPagination;
          };
        } | null = await getUsersList(
          page,
          searchQuery,
          selectedTab === 0 ? 'all' : selectedTab === 1 ? 'fans' : 'friends'
        );
        if (res) {
          if (selectedTab === 0) {
            if (page === 1) {
              setAllUsers(res.data.users);
            } else {
              setAllUsers([...allUsers, ...res.data.users]);
            }
          } else if (selectedTab === 1) {
            if (page === 1) {
              setFans(res.data.users);
            } else {
              setFans([...allUsers, ...res.data.users]);
            }
          } else {
            if (page === 1) {
              setFriends(res.data.users);
            } else {
              setFriends([...allUsers, ...res.data.users]);
            }
          }
          setPage(res.data.pagination.page);
          pageRef.current.currentPage = res.data.pagination.page;
          pageRef.current.totalPages = res.data.pagination.pages;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsUserListLoading(false);
      }
    },
    [selectedTab, allUsers]
  );

  const delayedSearch = useCallback(
    (searchQuery: string) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      if (searchQuery) {
        timerRef.current = setTimeout(() => {
          getAllUsers(searchQuery, page);
        }, 500);
      } else {
        getAllUsers(searchQuery, page);
      }
    },
    [selectedTab]
  );

  const getCurrentSearchQuery = useCallback(
    (selectedIndex: number) => {
      if (selectedIndex === 0) {
        return searchQuery.all;
      } else if (selectedIndex === 1) {
        return searchQuery.fans;
      } else {
        return searchQuery.friends;
      }
    },
    [searchQuery]
  );

  useEffect(() => {
    if (!loaderRef.current || !pageRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the element is fully in view in the viewport
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            if (pageRef.current.currentPage < pageRef.current.totalPages) {
              pageRef.current.currentPage += 1; // Update current page
              getAllUsers(
                getCurrentSearchQuery(selectedTab),
                pageRef.current.currentPage
              );
            }
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 1 } // Observe at different visibility levels
    );

    observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
      observer.disconnect();
    };
  }, [getAllUsers, isUserListLoading, loaderRef, selectedTab]);

  useEffect(() => {
    if (selectedTab === 0) {
      delayedSearch(searchQuery.all);
    } else if (selectedTab === 1) {
      delayedSearch(searchQuery.fans);
    } else {
      delayedSearch(searchQuery.friends);
    }
  }, [delayedSearch, searchQuery, selectedTab]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;
    if (error) {
      timeout = setTimeout(() => {
        setError(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error]);

  const panes = [
    {
      menuItem: 'All',
      render: () => (
        <TabPane className="" attached={false}>
          <input
            className="input-border"
            type="text"
            placeholder="Invite others by name or Email"
            value={searchQuery.all}
            onChange={(e) => handleSearchChange('all', e.target.value)}
          />
          <UsersList
            type="all"
            userData={allUsers}
            handleClickOnSelectUser={handleClickOnSelectUser}
            selectedUsers={selectedUsers}
            isUserListLoading={isUserListLoading}
            ref={loaderRef}
          />
        </TabPane>
      ),
    },
    {
      menuItem: 'Fans',
      render: () => (
        <TabPane attached={false}>
          <input
            className="input-border"
            type="text"
            placeholder="Invite others by name or Email"
            value={searchQuery.fans}
            onChange={(e) => handleSearchChange('fans', e.target.value)}
          />
          <UsersList
            type="fans"
            userData={fans}
            handleClickOnSelectUser={handleClickOnSelectUser}
            selectedUsers={selectedUsers}
            isUserListLoading={isUserListLoading}
            ref={loaderRef}
          />
        </TabPane>
      ),
    },
    {
      menuItem: 'Friends',
      render: () => (
        <TabPane attached={false}>
          <input
            className="input-border"
            type="text"
            placeholder="Invite others by name or Email"
            value={searchQuery.friends}
            onChange={(e) => handleSearchChange('friends', e.target.value)}
          />
          <UsersList
            type="friends"
            userData={friends}
            handleClickOnSelectUser={handleClickOnSelectUser}
            selectedUsers={selectedUsers}
            isUserListLoading={isUserListLoading}
            ref={loaderRef}
          />
        </TabPane>
      ),
    },
    {
      menuItem: 'External',
      render: () => (
        <TabPane attached={false}>
          <ExternalUser
            isEnabled={isEnabled}
            setIsEnabled={setIsEnabled}
            emails={emails}
            setEmails={setEmails}
            texts={texts}
            setTexts={setTexts}
            errors={errors}
          />
        </TabPane>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={() => setOpen()}
      basic
      style={{
        position: 'fixed',
        top: '0',
        right: '0',
        margin: '0',
        height: '100vh',
        maxWidth: '500px',
        width: '100%',
        background: '#1D0232',
        overflow: 'auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Modal.Header style={{ padding: 0, border: 'none', marginBottom: 15 }}>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column width={4}>
              <Button
                style={{ background: 'none', padding: 0, margin: 0 }}
                onClick={setOpen}
                primary
              >
                <Icon name="arrow left" />
              </Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <h3
                className="text-gradient-blue"
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontSize: 16,
                  padding: '3px 0',
                }}
              >
                Invite People
              </h3>
            </Grid.Column>
            <Grid.Column width={4}>
              <div style={{ textAlign: 'end' }}>
                <Button
                  className={`text-gradient-pink ${
                    selectedUsers.length || isEnabled.email || isEnabled.text
                      ? ''
                      : 'send-invite-btn'
                  }`}
                  style={{
                    background: 'none',
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    textAlign: 'end',
                  }}
                  onClick={async () => {
                    if (isEnabled.email) {
                      const isInvalidEmails = emails
                        .split(' ')
                        .join('')
                        .split(',')
                        .every((email) => emailRegex.test(email));
                      if (!isInvalidEmails) {
                        setErrors({ ...errors, emailError: 'Invalid emails.' });
                        return;
                      }
                    }
                    if (isEnabled.text) {
                      const isValidPhone = texts
                        .split(' ')
                        .join('')
                        .split(',')
                        .every((phone) => phoneRegex.test(phone));
                      if (!isValidPhone) {
                        setErrors({
                          ...errors,
                          phoneError: 'Invalid phone numbers.',
                        });
                        return;
                      }
                    }
                    setErrors({ emailError: '', phoneError: '' });
                    const res = await sendInvite(
                      roomId!,
                      ['Individuals'],
                      selectedUsers.map((i) => i.id.toString()),
                      isEnabled.email
                        ? ['email']
                        : isEnabled.text
                        ? ['text']
                        : isEnabled.email && isEnabled.text
                        ? ['email', 'text']
                        : [],
                      emails,
                      texts
                    );
                    if (res) {
                      setSuccessMessage(res.data.message);
                      setOpenNotificatino(true);
                      setTimeout(() => {
                        setOpen();
                      }, 1500);
                    }
                  }}
                >
                  Send Invite
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h6
            style={{
              margin: 0,
              fontSize: 14,
              fontWeight: 400,
              color: '#AE8A27',
              textTransform: 'uppercase',
            }}
          >
            You can select upto 50 people
          </h6>
          <div>
            <Popup
              on="click"
              basic
              trigger={
                <Button
                  className="text-gradient-orange"
                  style={{
                    padding: 0,
                    background: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  Copy Link
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_17686_8311"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M7 5.61735V3.66602C7 3.13558 7.21071 2.62687 7.58579 2.2518C7.96086 1.87673 8.46957 1.66602 9 1.66602H26.3333C26.8638 1.66602 27.3725 1.87673 27.7475 2.2518C28.1226 2.62687 28.3333 3.13558 28.3333 3.66602V20.9993C28.3333 21.5298 28.1226 22.0385 27.7475 22.4136C27.3725 22.7886 26.8638 22.9993 26.3333 22.9993H24.3447"
                        stroke="white"
                        stroke-width="2"
                      />
                      <path
                        d="M22.3337 5.66602H3.66699C2.56242 5.66602 1.66699 6.56145 1.66699 7.66602V26.3327C1.66699 27.4373 2.56242 28.3327 3.66699 28.3327H22.3337C23.4382 28.3327 24.3337 27.4373 24.3337 26.3327V7.66602C24.3337 6.56145 23.4382 5.66602 22.3337 5.66602Z"
                        fill="#555555"
                        stroke="white"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2936 14.4068L14.8216 10.7335C15.7889 9.76613 17.3796 9.78679 18.3736 10.7815C19.3676 11.7761 19.3889 13.3661 18.4216 14.3335L17.1483 15.6821M7.97761 18.1648C7.63761 18.5048 6.93428 19.1848 6.93428 19.1848C5.96628 20.1521 5.93961 21.8768 6.93428 22.8715C7.92761 23.8648 9.51828 23.8868 10.4863 22.9188L13.9289 19.7935"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.442 17.884C10.999 17.4441 10.7311 16.8582 10.688 16.2354C10.6627 15.886 10.7127 15.5353 10.8346 15.2069C10.9565 14.8786 11.1475 14.5802 11.3947 14.332M13.8807 16.2394C14.8747 17.2334 14.896 18.824 13.9287 19.792"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_17686_8311)">
                      <path
                        d="M-1 -1H31V31H-1V-1Z"
                        fill="url(#paint0_linear_17686_8311)"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_17686_8311"
                        x1="-1"
                        y1="15"
                        x2="31"
                        y2="15"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#C24545" />
                        <stop offset="1" stop-color="#E9D362" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Button>
              }
              closeOnEscape
              closeOnDocumentClick
              closeOnPortalMouseLeave
              closeOnTriggerClick
              position="top center"
              content="Copied"
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'auto',
            maxWidth: 460,
            alignItems: 'center',
            gap: 5,
            padding: '10px 0',
          }}
        >
          {selectedUsers.map((item) => {
            return (
              <div
                style={{
                  width: 65,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
                key={item.id}
              >
                <div style={{ position: 'relative' }}>
                  <Userprofile item={item} />
                  <button
                    className="profile-close"
                    type="button"
                    onClick={() => {
                      setSelectedUsers(
                        selectedUsers.filter((i) => i.id !== item.id)
                      );
                    }}
                  >
                    <Icon name="close" />
                  </button>
                </div>
                <h4
                  className="truncate"
                  style={{
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 200,
                    textAlign: 'center',
                    color: '#27AE60',
                  }}
                >
                  {item.first_name} {item.last_name}
                </h4>
              </div>
            );
          })}
        </div>
        <Container>
          <Tab
            activeIndex={selectedTab}
            panes={panes}
            onTabChange={(_, data) => {
              setSearchQuery({ all: '', fans: '', friends: '' });
              setPage(1);
              setSelectedTab(data.activeIndex as number);
            }}
          />
        </Container>
        {openNotification && (
          <div
            className="ui success message"
            style={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              alignItems: 'center',
            }}
          >
            <i
              className="close icon"
              style={{ marginTop: 4 }}
              onClick={() => {
                setOpenNotificatino(false);
              }}
            ></i>
            <div className="header" style={{ margin: '0 10px 0 0' }}>
              {successMessage}
            </div>
          </div>
        )}
        {error && (
          <div
            className="ui red message"
            style={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              alignItems: 'center',
            }}
          >
            <i
              className="close icon"
              style={{ marginTop: 4 }}
              onClick={() => {
                setError(false);
              }}
            ></i>
            <div className="header" style={{ margin: '0 10px 0 0' }}>
              You can select upto 50 people
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default InvitePeople;
