import React, { useImperativeHandle, useRef } from 'react';
import { IUserList } from './InvitePeople';
import Userprofile from '../Userprofile/Userprofile';

export interface IUsersList {
  userData: any[];
  type: 'all' | 'fans' | 'friends';
  handleClickOnSelectUser: (item: IUserList) => void;
  selectedUsers: IUserList[];
  isUserListLoading: boolean;
}

const UsersList = React.forwardRef<HTMLDivElement | null, IUsersList>(
  (
    {
      userData,
      type,
      handleClickOnSelectUser,
      selectedUsers,
      isUserListLoading,
    },
    ref
  ) => {
    return (
      <div
        style={{
          maxHeight: selectedUsers.length
            ? 'calc(100dvh - 300px)'
            : 'calc(100dvh - 230px)',
          overflow: 'auto',
          marginTop: 10,
        }}
      >
        {userData.length ? (
          <>
            {userData.map((item) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '10px 0',
                  }}
                  key={item.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 15,
                      paddingLeft: 10,
                    }}
                  >
                    <Userprofile item={item} />
                    <h4 style={{ margin: 0, color: '#27AE60' }}>
                      {item.first_name} {item.last_name}
                    </h4>
                  </div>

                  <label className="custom-container">
                    <input
                      type="checkbox"
                      name="checboxname"
                      id="checboxname"
                      checked={
                        selectedUsers.find((i) => i.id === item.id)
                          ? true
                          : false
                      }
                      onChange={() => {
                        // if (selectedUsers.length === 50) {
                        //   return;
                        // }
                        handleClickOnSelectUser(item);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              );
            })}

            <div className="w-full h-5" ref={ref}>
              {isUserListLoading && (
                <h5 style={{ color: 'white', textAlign: 'center' }}>
                  Loading...
                </h5>
              )}
            </div>
          </>
        ) : (
          <h4 style={{ textAlign: 'center', margin: '2rem 0' }}>
            No {type === 'all' ? 'users' : type} found
          </h4>
        )}
      </div>
    );
  }
);

export default UsersList;
